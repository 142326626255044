import { createSlice } from '@reduxjs/toolkit';
import {
  GameModes,
  GameSets,
  BossList,
  HeroList,
} from './gameLists';

export const LOCALSTORAGE_ID = 'enrichedGame';

const defaultInitialState = {
  players: {},
  availableGameSets: [],
  availableGameModes: [],
};

const readInitialState = baseInitialState => {
    const initialState = {
        ...baseInitialState
    }

    const read = JSON.parse(localStorage.getItem(LOCALSTORAGE_ID));
    if (read) {
        if (read.players) {
            initialState.players = read.players;
        }
        if (read.availableGameSets) {
            initialState.availableGameSets = read.availableGameSets;
        }
    }

    updateGameModes(initialState);

    return initialState;
}

const updateGameModes = state => {
    state.availableGameModes = GameModes
        .filter(mode => mode.required_sets.every(set => state.availableGameSets.includes(set)))
        .map(mode => ({...mode, bosses: [], completed: false}));

    BossList.forEach(boss => {
        state.availableGameModes.forEach(mode => {
            if (state.availableGameSets.includes(boss.from) && mode.boss_sets.includes(boss.from)) {
                const enrichedBoss = {
                    ...boss,
                    defeated: false,
                };
                updateBossStats(state, mode, enrichedBoss);

                mode.bosses.push(enrichedBoss);
            }
        });
    })

    state.availableGameModes.forEach(mode => {
        mode.completed = mode.bosses.every(boss => boss.defeated);
    });
}

const updateBossStats = (state, mode, boss) => {
    const allPlayerBossHeroMaps = Object.values(state.players).map(playerModes => playerModes[mode.id] || []);
    const usableHeroes = getHeroesForMode(state.availableGameSets, mode);

    boss.currentDefeats = allPlayerBossHeroMaps.reduce((fights, bossHeroMaps) => (fights + (bossHeroMaps[boss.id]?.length || 0)), 0);
    boss.totalDefeats = Object.values(state.players).length * usableHeroes.length;
    boss.defeated = boss.currentDefeats === boss.totalDefeats;
}

const getHeroesForMode = (availableGameSets, mode) => {
    return HeroList
        .filter(hero => availableGameSets.includes(hero.from) && mode.hero_sets.includes(hero.from));
}

const addToListSafe = (sourceList, newItem) => {
    const res = [...sourceList || []];
    if (!res.includes(newItem)) {
        res.push(newItem)
    }
    return res;
}

const saveGameState = state => localStorage.setItem(LOCALSTORAGE_ID, JSON.stringify({players: state.players, availableGameSets: state.availableGameSets}));

export const gameDataSlice = createSlice({
  name: 'gameData',
  initialState: readInitialState(defaultInitialState),
  reducers: {
    setPersistentState: (state, {payload}) => {
        localStorage.setItem(LOCALSTORAGE_ID, payload.state);
    },
    clearGameState: state => {
        state.players = defaultInitialState.players;
        state.availableGameSets = defaultInitialState.availableGameSets;
        state.availableGameModes = defaultInitialState.availableGameModes;
        localStorage.removeItem(LOCALSTORAGE_ID);
        updateGameModes(state);
    },
    addPlayer: (state, {payload}) => {
        state.players[payload] = {};
        saveGameState(state);
        updateGameModes(state);
    },
    removePlayer: (state, {payload}) => {
        delete state.players[payload];
        saveGameState(state);
        updateGameModes(state);
    },
    setAvailableGameSets: (state, action) => {
        state.availableGameSets = action.payload;
        saveGameState(state);
        updateGameModes(state);
    },
    addDefeat: (state, action) => {
        const { modeId, bossId, players } = action.payload;

        for (const [player, heroId] of Object.entries(players)) {
            const playerModeMap = state.players[player] || {};
            const bossHeroMaps = playerModeMap[modeId] || {};
            bossHeroMaps[bossId] = addToListSafe(bossHeroMaps[bossId], heroId);
            playerModeMap[modeId] = bossHeroMaps;
            state.players[player] = playerModeMap;

            const mode = state.availableGameModes.find(mode => mode.id === modeId)
            const boss = mode.bosses.find(boss => boss.id === bossId);
            updateBossStats(state, mode, boss);
        }
        saveGameState(state);
    },
  },
});

export const { setPersistentState, clearGameState, addPlayer, removePlayer, setAvailableGameSets, addDefeat } = gameDataSlice.actions;


export const selectGameMode = (state, modeId) => GameModes.find(mode => mode.id === modeId);

export const selectBoss = (state, bossId) => BossList.find(boss => boss.id === bossId);

export const selectHero = (state, heroId) => HeroList.find(hero => hero.id === heroId);

export const selectPlayers = state => Object.keys(state.gameData.players);

export const selectAvailableGameModes = state => state.gameData.availableGameModes;

export const selectAvailableGameSetMap = state => GameSets.map(gameSet => ({...gameSet, available: state.gameData.availableGameSets.includes(gameSet.id)}));

const getBossHeroMap = (state, modeId, player, bossId) => (state.gameData.players[player][modeId] || {})[bossId] || [];

export const selectHeroesForModeAndBossByPlayer = (state, modeId, bossId, player) => {
    if (!modeId || !bossId || !player) {
        return HeroList
            .filter(hero => state.gameData.availableGameSets.includes(hero.from))
            .map(hero => ({
                ...hero,
                defeated: false
            }))
    }

    const mode = state.gameData.availableGameModes.find(mode => mode.id === modeId);
    return getHeroesForMode(state.gameData.availableGameSets, mode)
        .map(hero => ({
            ...hero,
            defeated: getBossHeroMap(state, modeId, player, bossId).includes(hero.id),
        }))
}

export default gameDataSlice.reducer;
