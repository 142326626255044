import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Header } from './Header';
import { Bosses } from './features/bosses/Bosses';
import { AvailableGameSets } from './features/settings/AvailableGameSets';
import { Players } from './features/settings/Players';
import { Settings } from './features/settings/Settings';

import {
    selectStatus,
    hasSets,
    hasPlayers,
} from './features/setup/setupSlice';

import './App.css';

function App() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const setupStatus = useSelector(selectStatus);
    const [settingsVisible, setSettingsVisible] = useState(false);

    return (
        <>
            <Header showSettingsButton={setupStatus === 'done'} settingsVisible={settingsVisible} onClickSettingsButton={() => setSettingsVisible(!settingsVisible)}/>
            { setupStatus === 'needs-sets' && <>
                <AvailableGameSets />
                <button onClick={() => dispatch(hasSets())}>{t('next')}</button>
            </>}
            { setupStatus === 'needs-players' &&  <>
                <Players />
                <button onClick={() => dispatch(hasPlayers())}>{t('next')}</button>
            </>}
            { setupStatus === 'done' && <>
                {/* FIXME display: none instead */}
                { settingsVisible ?
                    <Settings />
                    :
                    <Bosses />
                }
            </>}
        </>
    );
}

export default App;
