import { createSlice } from '@reduxjs/toolkit';

export const gameSelectionSlice = createSlice({
    name: 'gameSelection',
    initialState: {
        modeId: '',
        bossId: '',
        players: {},
    },
    reducers: {
        setSelectedModeId: (state, {payload}) => {
            state.modeId = payload;
        },
        setSelectedBossId: (state, {payload}) => {
            state.bossId = payload;
        },
        setSelectedHero: (state, {payload}) => {
            state.players = {...state.players, ...payload}
        },
        unsetSelectedHero: (state, {payload}) => {
            const {[payload.player]: _, ...newPlayers} = state.players;
            state.players = newPlayers;
        },
        resetSelections: state => {
            state.modeId = '';
            state.bossId = '';
            state.players = {};
        }
    },
});

export const { setSelectedModeId, setSelectedBossId, setSelectedHero, unsetSelectedHero, resetSelections } = gameSelectionSlice.actions;

export const selectSelectedModeId = (state) => state.gameSelection.modeId;

export const selectSelectedBossId = (state) => state.gameSelection.bossId;

export const selectSelectedHeroes = (state) => state.gameSelection.players;

export default gameSelectionSlice.reducer;
