import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Players } from './Players';
import { AvailableGameSets } from './AvailableGameSets';
import {
    LOCALSTORAGE_ID as GameDataStorageId,
    setPersistentState as setGameDataPersistentState,
    clearGameState,
} from '../game/gameDataSlice';
import {
    LOCALSTORAGE_ID as SetupStorageId,
    setPersistentState as setSetupPersistenState,
    clearSetupState,
} from '../setup/setupSlice';

import { ExportModal } from './ExportModal';

import demoData from './demoData.json';

import styles from './Settings.module.css';

export function Settings() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [exportVisible, setExportVisible] = useState(false);

    const clearAllState = () => {
        dispatch(clearGameState());
        dispatch(clearSetupState());
        window.location.reload(false);
    }

    const setDemoState = () => {
        dispatch(setGameDataPersistentState({state: demoData[GameDataStorageId]}));
        dispatch(setSetupPersistenState({state: demoData[SetupStorageId]}));
        window.location.reload(false);
    }

    return (
        <div className={styles.container}>
            <h2>{t('settings.title')}</h2>
            <Players />
            <AvailableGameSets />

            <div>
                <h3>{t('settings.storage')}</h3>
                <button
                    aria-label={t('settings.reset')}
                    onClick={clearAllState}
                    >
                    {t('settings.reset')}
                </button>
                <button
                    aria-label={t('settings.export')}
                    onClick={() => setExportVisible(true)}
                    >
                    {t('settings.export')}
                </button>
                <button
                    aria-label={t('settings.demo')}
                    onClick={setDemoState}
                    >
                    {t('settings.demo')}
                </button>
            </div>

            {exportVisible && <ExportModal
                onClosed={() => setExportVisible(false)}
                />}
        </div>
    );
}
