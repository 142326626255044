import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from "react-i18next";

import {
  selectAvailableGameModes,
} from '../game/gameDataSlice';
import {
  setSelectedModeId,
  setSelectedBossId,
  selectSelectedModeId,
} from '../game/gameSelectionSlice';

import { getIntlObjName } from '../game/intl.js';
import { BossCard } from './BossCard';
import { Defeater } from '../defeater/Defeater';

import { Modal } from '../modal/Modal';

import styles from './Bosses.module.css';

export const Bosses = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const availableModes = useSelector(selectAvailableGameModes);
    const selectedModeId = useSelector(selectSelectedModeId);
    const [playersSelectionVisible, setPlayersSelectionVisible] = useState(false);

    useEffect(() => {
        const modeId = availableModes.find(mode => !mode.completed)?.id || '';
        dispatch(setSelectedModeId(modeId));
    }, [availableModes, dispatch])

    const selectedMode = availableModes.find(mode => mode.id === selectedModeId) || {bosses:[]};

    const selectGameMode = modeId => {
        dispatch(setSelectedModeId(modeId));
    }

    const selectBoss = bossId => {
        dispatch(setSelectedBossId(bossId));
        setPlayersSelectionVisible(true);
    }

    const randomModeBoss = onlyToDefeat => {
        let modeChoices = availableModes;
        if (onlyToDefeat) {
            modeChoices = modeChoices.filter(mode => !mode.completed)
        }
        const randomMode = modeChoices[Math.floor(Math.random()*modeChoices.length)];

        let bossChoices = randomMode.bosses;
        if (onlyToDefeat) {
            bossChoices = bossChoices.filter(boss => !boss.defeated)
        }
        const randomBoss = bossChoices[Math.floor(Math.random()*bossChoices.length)];

        const modeId = randomMode.id;
        const bossId = randomBoss.id;

        dispatch(setSelectedModeId(modeId));
        dispatch(setSelectedBossId(bossId));
        setPlayersSelectionVisible(true);
    }

    const handleModalClose = () => setPlayersSelectionVisible(false)

    return (
        <>
            <div className={styles.actionButtonContainer}>
                <button onClick={() => randomModeBoss(false)}>{t('bosses.random')}</button>
                <button onClick={() => randomModeBoss(true)}>{t('bosses.random_remaining')}</button>
            </div>
            <div className={styles.modesContainer}>
                {availableModes.map(mode => (
                    <button
                      key={mode.id}
                      className={classNames(styles.mode, {[styles.selected]: selectedModeId === mode.id})}
                      onClick={()=> selectGameMode(mode.id)}
                    >
                      {getIntlObjName(mode)}
                    </button>
                ))}
            </div>
            <div className={styles.bossesContainer}>
                {selectedMode.bosses.map(boss => <BossCard key={boss.id} boss={boss} onClick={() => selectBoss(boss.id)}/>)}
            </div>

            {playersSelectionVisible && <Modal onClosed={handleModalClose}>
                <Defeater
                    onClosed={handleModalClose}
                    />
                </Modal>}
        </>
    );
}
