import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getIntlObjName } from '../game/intl.js';
import {
  selectHero,
} from '../game/gameDataSlice';
import styles from './PHeroCard.module.css';

export const PHeroCard = ({heroId, player, defeated, selected, unavailable, onSelected, onHeroRemove}) => {
    const hero = useSelector(state => selectHero(state, heroId));
    if (!heroId) return;

    const imgClasses = classNames(styles.heroImg, {
        [styles.selected]: selected,
        [styles.unavailableDefeated]: unavailable && defeated,
        [styles.unavailable]: unavailable,
        [styles.defeated]: defeated,
    });

    return (
        <div className={styles.heroCard} onClick={() => onSelected && !unavailable && onSelected()}>
            <img
                className={imgClasses}
                src={`/heroes/${hero.id}.png`}
                alt={''}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src="/heroes/missing.png";
                }}
            />
            {player && <span className={styles.playerName}>{player}</span>}
            {player && <button className={styles.heroRemove} onClick={onHeroRemove}>X</button>}
            <span className={styles.heroName}>{getIntlObjName(hero)}</span>
        </div>
    );
}
