import styles from './Modal.module.css';

export const Modal = ({children, onClosed}) => {
    return (
        <div className={styles.container} onClick={(e) => e.target === e.currentTarget && onClosed()}>
            <div className={styles.modalContent}>
                {children}
            </div>
        </div>
    );
}
