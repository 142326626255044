import {
    useState,
    useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import {
  selectHeroesForModeAndBossByPlayer,
} from '../game/gameDataSlice';
import {
  setSelectedHero,
  unsetSelectedHero,
  selectSelectedModeId,
  selectSelectedBossId,
  selectSelectedHeroes,
} from '../game/gameSelectionSlice';
import { HeroCard } from './HeroCard';

import styles from './PlayerHeroPicker.module.css';


export const PlayerHeroPicker = ({player, onSelected}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedModeId = useSelector(selectSelectedModeId);
    const selectedBossId = useSelector(selectSelectedBossId);
    const selectedHeroes = useSelector(selectSelectedHeroes);
    const heroes = useSelector(state => selectHeroesForModeAndBossByPlayer(state, selectedModeId, selectedBossId, player));

    const [playerSelected, setPlayerSelected] = useState(true);

    const onPlayerSelected = selected => {
        if (!selected) {
            dispatch(unsetSelectedHero({ player }));
        }
        setPlayerSelected(selected);
        onSelected(selected);
    }

    const otherPlayerHeroesExcept = useCallback(() => {
        const { [player]: omitted, ...otherPlayerSelections } = selectedHeroes;
        return Object.values(otherPlayerSelections);
    }, [player, selectedHeroes]);
    const otherSelectedHeroes = otherPlayerHeroesExcept();

    const setPlayerSelection = useCallback(heroId => {
        dispatch(setSelectedHero({ [player]: heroId }));
    }, [player, dispatch]);

    const selectRandom = useCallback(onlyToDefeat => {
        let heroChoices = heroes.filter(hero => !otherSelectedHeroes.includes(hero.id));
        if (onlyToDefeat) {
            heroChoices = heroChoices.filter(hero => !hero.defeated)
        }
        if (!heroChoices.length) {
            dispatch(unsetSelectedHero({ player }));
            return;
        }
        const randomHero = heroChoices[Math.floor(Math.random()*heroChoices.length)];
        setPlayerSelection(randomHero.id);
    }, [heroes, otherSelectedHeroes, dispatch, player, setPlayerSelection]);

    return (
        <div className={styles.playerSelection}>
            <label>
            <input
              type="checkbox"
              checked={playerSelected}
              onChange={e => onPlayerSelected(e.target.checked)}
            />
            {player}
            </label>
            <button onClick={() => selectRandom(false)}>{t('playerHeroPicker.random')}</button>
            <button onClick={() => selectRandom(true)}>{t('playerHeroPicker.random_remaining')}</button>
            {playerSelected && <div className={styles.heroesContainer}>
                {heroes.map(hero => <HeroCard
                    key={hero.id}
                    heroId={hero.id}
                    defeated={hero.defeated}
                    selected={hero.id === selectedHeroes[player]}
                    unavailable={otherSelectedHeroes.includes(hero.id)}
                    onSelected={() => hero.id !== selectedHeroes[player] && setPlayerSelection(hero.id)}
                />)}
            </div>}
        </div>
    );
}
