import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getIntlObjName } from '../game/intl.js';

import styles from './BossCard.module.css';

import {
  selectSelectedBossId,
} from '../game/gameSelectionSlice';

export const BossCard = ({boss, onClick}) => {
    const selectedBossId = useSelector(selectSelectedBossId);
    const isSelected = boss.id === selectedBossId;

    return (
        <div className={styles.boss} onClick={onClick}>
            <img
                className={classNames(styles.bossImg, {[styles.selected]: isSelected, [styles.defeated]: boss.defeated && !isSelected})}
                src={`/bosses/${boss.id}.png`}
                alt={''}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src="/bosses/missing.png";
                }}
            />
            <span className={styles.bossName}>{getIntlObjName(boss)}</span>
            <span className={styles.fightCounter}>{boss.currentDefeats + '/' + boss.totalDefeats}</span>
        </div>
    );
};
