import styles from './Header.module.css';

export const Header = ({showSettingsButton, settingsVisible, onClickSettingsButton}) => {
    return (
        <div className={styles.headerContainer}>
            <h1 className={styles.title}>One Deck Dungeon Randomizer</h1>
            <div>
            { showSettingsButton && <button onClick={onClickSettingsButton}>{settingsVisible ? '✕' : '⚙'}</button>}
            </div>
        </div>
    );
}
