import { useDispatch, useSelector } from 'react-redux';

import { getIntlObjName } from '../game/intl.js';
import {
  selectGameMode,
  selectBoss,
} from '../game/gameDataSlice';
import {
  unsetSelectedHero,
  selectSelectedModeId,
  selectSelectedBossId,
  selectSelectedHeroes,
} from '../game/gameSelectionSlice';
import { PHeroCard } from './PHeroCard';

import styles from './DefeaterSummary.module.css';

const Title = () => {
    const selectedModeId = useSelector(selectSelectedModeId);
    const selectedBossId = useSelector(selectSelectedBossId);
    const mode = useSelector(state => selectGameMode(state, selectedModeId));
    const boss = useSelector(state => selectBoss(state, selectedBossId));

    return <h2>{`${getIntlObjName(mode)} - ${getIntlObjName(boss)}`}</h2>;
}

export const DefeaterSummary = () => {
    const dispatch = useDispatch();
    const selectedModeId = useSelector(selectSelectedModeId);
    const selectedBossId = useSelector(selectSelectedBossId);
    const selectedHeroes = useSelector(selectSelectedHeroes);

    const handleHeroRemove = player => {
        dispatch(unsetSelectedHero({ player }));
    };

    return (
        <>
            {(selectedModeId && selectedBossId) && <Title/>}
            <div className={styles.heroesContainer}>
                {Object.entries(selectedHeroes).map(([player, heroId]) => (
                    <PHeroCard key={heroId} player={player} heroId={heroId} onHeroRemove={() => handleHeroRemove(player)} />
                ))}
            </div>
        </>
    );
}

