import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import {
    addPlayer,
    removePlayer,
    selectPlayers,
} from '../game/gameDataSlice';

import styles from './Players.module.css';

export function Players() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const players = useSelector(selectPlayers);

    const [newPlayerName, setNewPlayerName] = useState('');

    const addPlayerClick = () => {
        dispatch(addPlayer(newPlayerName));
        setNewPlayerName('');
    }

    const removePlayerClick = player => {
        dispatch(removePlayer(player));
        setNewPlayerName('');
    }

    return (
        <div className={styles.container}>
            <h3>{t('players.title')}</h3>
            {players.length ?
            <ul>
                {players.map(player => <li key={player}>
                    {player}
                    <button onClick={() => removePlayerClick(player)}>X</button>
                </li>)}
            </ul> :
            <p>{t('help.add_player')}</p>
            }

            <div>
                <input value={newPlayerName} onChange={(e) => setNewPlayerName(e.target.value)}/>
                <button
                    aria-label={t('players.add')}
                    disabled={!newPlayerName}
                    onClick={addPlayerClick}
                    >
                    {t('players.add')}
                </button>
            </div>
        </div>
    );
}
