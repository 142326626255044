import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import {
    LOCALSTORAGE_ID as GameDataStorageId,
} from '../game/gameDataSlice';
import {
    LOCALSTORAGE_ID as SetupStorageId,
} from '../setup/setupSlice';

import { Modal } from '../modal/Modal';

export const ExportModal = ({onClosed}) => {
    const { t } = useTranslation();
    const [persistentStorage, setPersistenStorage] = useState('');

    useEffect(() => {
        const localStorageData = [GameDataStorageId, SetupStorageId]
            .map(id => [id, localStorage.getItem(id)])
            .reduce((totalData, [id, data]) => ({...totalData, [id]: data}), {});
        setPersistenStorage(JSON.stringify(localStorageData));
    }, [])

    const exportState = () => {
        navigator.clipboard.writeText(persistentStorage)
        alert(t('settings.copiedToClipboard'))
    }

    const downloadState = () => {
        var a = document.createElement("a")
        a.href = URL.createObjectURL(
            new Blob([persistentStorage], {type:"application/json"})
        )
        a.download = `${new Date().toISOString().split('T')[0]}_odd-randomizer.json`
        a.click()
        URL.revokeObjectURL(a.href)
    }

    return (
        <Modal onClosed={onClosed}>
            <h4>Export</h4>
            <pre>{persistentStorage}</pre>
            <button
                aria-label={t('settings.copyToClipboard')}
                onClick={exportState}
            >
                {t('settings.copyToClipboard')}
            </button>
            <button
                aria-label={t('settings.download')}
                onClick={downloadState}
            >
                {t('settings.download')}
            </button>
            <button
                aria-label={t('close')}
                onClick={onClosed}
            >
                {t('close')}
            </button>
        </Modal>
    );
}
