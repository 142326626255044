import { createSlice } from '@reduxjs/toolkit';

export const LOCALSTORAGE_ID = 'setup';

const initialState = {
  status: 'needs-sets'
};

const readInitialState = baseInitialState => {
    const read = JSON.parse(localStorage.getItem(LOCALSTORAGE_ID));

    return {
        ...baseInitialState,
        status: read?.status || baseInitialState.status,
    }
}

export const setupSlice = createSlice({
  name: 'setup',
  initialState: readInitialState(initialState),
  reducers: {
    setPersistentState: (state, {payload}) => {
        localStorage.setItem(LOCALSTORAGE_ID, payload.state);
    },
    clearSetupState: (state) => {
        state.status = 'needs-sets';
        localStorage.removeItem(LOCALSTORAGE_ID);
    },
    hasSets: (state) => {
        state.status = 'needs-players';
        localStorage.setItem(LOCALSTORAGE_ID, JSON.stringify(state))
    },
    hasPlayers: (state) => {
        state.status = 'done';
        localStorage.setItem(LOCALSTORAGE_ID, JSON.stringify(state))
    },
  },
});

export const { setPersistentState, clearSetupState, hasSets, hasPlayers } = setupSlice.actions;


export const selectStatus = (state) => state.setup.status;

export default setupSlice.reducer;
