export const GameModes = [
    {
        id: 'base',
        name: 'Base game',
        name_it: 'Gioco base',
        required_sets: ['base'],
        hero_sets: ['base'],
        boss_sets: ['base'],
    },
    {
        id: 'exp',
        name: 'Expansion',
        name_it: 'Espansione',
        required_sets: ['fos'],
        hero_sets: ['fos'],
        boss_sets: ['fos'],
    },
    {
        id: 'hybrid',
        name: 'Hybrid deck',
        name_it: 'Mazzo ibrido',
        required_sets: ['base', 'fos'],
        hero_sets: ['base', 'fos'],
        boss_sets: ['base', 'fos'],
    },
    {
        id: 'bonus_packs',
        name: 'Bonus Packs',
        name_it: 'Bonus Packs',
        required_sets: ['base', 'fos'],
        hero_sets: ['base', 'fos', 'bonus1', 'bonus2', 'bonus3'],
        boss_sets: ['bonus1', 'bonus2', 'bonus3'],
    },
];

export const GameSets = [
    {
        id: 'base',
        name: 'Base',
        name_it: 'Base',
    },
    {
        id: 'fos',
        name: 'Forest of Shadows',
        name_it: 'Foresta delle Ombre',
    },
    {
        id: 'bonus1',
        name: 'Bonus Pack 1',
        name_it: 'Bonus Pack 1',
    },
    {
        id: 'bonus2',
        name: 'Bonus Pack 2',
        name_it: 'Bonus Pack 2',
    },
    {
        id: 'bonus3',
        name: 'Bonus Pack 3',
        name_it: 'Bonus Pack 3',
    },
];

export const BossList = [
    {
        id: 'dragon_cave',
        name: 'Dragon\'s Cave',
        name_it: 'Tana del Drago',
        from: 'base'
    },
    {
        id: 'hydra_reef',
        name: 'Hydra\'s Reef',
        name_it: 'Scoglio dell\'Idra',
        from: 'base'
    },
    {
        id: 'lich_Tomb',
        name: 'Lich\'s Tomb',
        name_it: 'Sepolcro del Lich',
        from: 'base'
    },
    {
        id: 'minotaur_maze',
        name: 'Minotaur\'s maze',
        name_it: 'Dedalo del Minotauro',
        from: 'base'
    },
    {
        id: 'yeti_cavern',
        name: 'Yeti\'s Cavern',
        name_it: 'Caverna dello Yeti',
        from: 'base'
    },
    {
        id: 'lair_indrax',
        name: 'Lair Of Indrax',
        name_it: 'Tana di Indrax',
        from: 'fos'
    },
    {
        id: 'mudlands',
        name: 'The Mudlands',
        name_it: 'Lande Fangose',
        from: 'fos'
    },
    {
        id: 'realm_venom',
        name: 'Realm Of Venom',
        name_it: 'Reame Tossico',
        from: 'fos'
    },
    {
        id: 'smoldering_ruins',
        name: 'Smoldering Ruins',
        name_it: 'Rovine Ardenti',
        from: 'fos'
    },
    {
        id: 'vile_roots',
        name: 'The Vile Roots',
        name_it: 'Radici Corrotte',
        from: 'fos'
    },
    {
        id: 'lair_phoenix',
        name: 'Lair of Phoenix',
        name_it: 'Tana della Fenice',
        from: 'bonus1'
    },
    {
        id: 'lair_mutant',
        name: 'Lair of Mutant',
        name_it: 'Tana del Mutante',
        from: 'bonus3'
    },
];

export const HeroList = [
    {
        id: 'archer',
        name: 'Archer',
        name_it: 'Arciere',
        from: 'base'
    },
    {
        id: 'mage',
        name: 'Mage',
        name_it: 'Maga',
        from: 'base'
    },
    {
        id: 'paladin',
        name: 'Paladin',
        name_it: 'Paladina',
        from: 'base'
    },
    {
        id: 'rogue',
        name: 'Rogue',
        name_it: 'Ladra',
        from: 'base'
    },
    {
        id: 'warrior',
        name: 'Warrior',
        name_it: 'Guerriera',
        from: 'base'
    },
    {
        id: 'alchemist',
        name: 'Alchemist',
        name_it: 'Alchimista',
        from: 'fos'
    },
    {
        id: 'druid',
        name: 'Druid',
        name_it: 'Druida',
        from: 'fos'
    },
    {
        id: 'hunter',
        name: 'Hunter',
        name_it: 'Cacciatrice',
        from: 'fos'
    },
    {
        id: 'slayer',
        name: 'Slayer',
        name_it: 'Ranger',
        from: 'fos'
    },
    {
        id: 'warden',
        name: 'Warden',
        name_it: 'Custode',
        from: 'fos'
    },
    {
        id: 'caliana',
        name: 'Caliana',
        name_it: 'Caliana',
        from: 'bonus1'
    },
    {
        id: 'billygoat',
        name: 'Billygoat',
        name_it: 'Billygoat',
        from: 'bonus2'
    },
    {
        id: 'elm_street',
        name: 'Elm Street',
        name_it: 'Elm Street',
        from: 'bonus2'
    },
    {
        id: 'druid2',
        name: 'Druid',
        name_it: 'Druida',
        from: 'bonus3'
    },
    {
        id: 'warrior2',
        name: 'Warrior',
        name_it: 'Guerriera',
        from: 'bonus3'
    },
];
