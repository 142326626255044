import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, useStore } from 'react-redux';

import {
    addDefeat,
    selectPlayers,
    selectHeroesForModeAndBossByPlayer,
} from '../game/gameDataSlice';

import {
    resetSelections,
    setSelectedHero,
    selectSelectedModeId,
    selectSelectedBossId,
    selectSelectedHeroes,
} from '../game/gameSelectionSlice';
import { DefeaterSummary } from './DefeaterSummary';
import { PlayerHeroPicker } from './PlayerHeroPicker';

import styles from './Defeater.module.css';

export const Defeater = ({onClosed}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useStore();

    const selectedModeId = useSelector(selectSelectedModeId);
    const selectedBossId = useSelector(selectSelectedBossId);
    const selectedHeroes = useSelector(selectSelectedHeroes);
    const players = useSelector(selectPlayers)
    const [ selectedPlayers, setSelectedPlayers ] = useState(players.reduce((sp, player) => ({...sp, [player]: true}), {}));

    const applyDefeat = () => {
        dispatch(addDefeat({
            modeId: selectedModeId,
            bossId: selectedBossId,
            players: selectedHeroes
        }));
        dispatch(resetSelections());
        if (onClosed) {
            onClosed();
        }
    }

    const setRandomPlayerSelections = onlyToDefeat => {
        const randomPlayerSelections = {};
        players.forEach(player => {
            let heroChoices = selectHeroesForModeAndBossByPlayer(store.getState(), selectedModeId, selectedBossId, player)
                .filter(hero => !Object.values(randomPlayerSelections).includes(hero.id))
            if (onlyToDefeat) {
                heroChoices = heroChoices.filter(hero => !hero.defeated)
            }

            if (!heroChoices.length) {
                return;
            }

            const randomHero = heroChoices[Math.floor(Math.random()*heroChoices.length)];
            randomPlayerSelections[player] = randomHero.id;
        })
        dispatch(setSelectedHero(randomPlayerSelections));
    }

    return (
        <>
            <DefeaterSummary/>
            <div className={styles.actionButtonContainer}>
                <button onClick={() => setRandomPlayerSelections(false)}>{t('heroes.random')}</button>
                {selectedModeId && selectedBossId && <>
                    <button onClick={() => setRandomPlayerSelections(true)}>{t('heroes.random_remaining')}</button>
                    <button disabled={Object.values(selectedHeroes).length !== Object.values(selectedPlayers).filter(Boolean).length} onClick={applyDefeat}>{t('defeat')}</button>
                </>}
                {onClosed && <button onClick={onClosed}>{t('close')}</button>}
            </div>
            {players.map(player => <PlayerHeroPicker
                key={player}
                player={player}
                onSelected={selected => setSelectedPlayers({...selectedPlayers, [player]: selected})}
            />)}
        </>
    );
}
