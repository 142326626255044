import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enJson from './i18n/en.json';
import itJson from './i18n/it.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: enJson
            },
            it: {
                translation: itJson
            },
        },
        lng: "it", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });
