import { configureStore } from '@reduxjs/toolkit';
import gameDataReducer from '../features/game/gameDataSlice';
import setupReducer from '../features/setup/setupSlice';
import gameSelectionReducer from '../features/game/gameSelectionSlice';

export const store = configureStore({
  reducer: {
    gameData: gameDataReducer,
    setup: setupReducer,
    gameSelection: gameSelectionReducer,
  },
});
