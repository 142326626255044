import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import {
    setAvailableGameSets,
    selectAvailableGameSetMap
} from '../game/gameDataSlice';
import { getIntlObjName } from '../game/intl.js';

import styles from './AvailableGameSets.module.css';

export function AvailableGameSets() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const gameSets = useSelector(selectAvailableGameSetMap);

    const toggleGameSet = (changedSet, enabled) => {
        const updatedGameSets = gameSets.filter(set => set.id === changedSet.id ? enabled : set.available).map(set => set.id);
        dispatch(setAvailableGameSets(updatedGameSets));
    }

    return (
        <div className={styles.container}>
            <h3>{t('settings.availableGameSets')}</h3>
            {gameSets.map(set => <label key={set.id}>
                {getIntlObjName(set)}
              <input
                type="checkbox"
                checked={set.available}
                onChange={e => toggleGameSet(set, e.target.checked)}
                />
            </label>)}
        </div>
    );
}
